<template>
    <div>
        <div class="modal fade" id="modalShow" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true"
             style="position: fixed;">
            <button type="button" class="closeXReject" id="closeModal" data-dismiss="modal" aria-label="Close"
                    hidden></button>
            <div class="modal-dialog lg" role="document">
                <div class="modal-content">
                    <div data-cy="reserve_modal_title" class="modal-header hearder-custom">
                        <div>
                            <h4 class="modal-title">Realmente deseja rejeitar a proposta?</h4>
                            <button type="button" class="close close-custom" id="close" data-dismiss="modal"
                                    aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <div class="modal-body hearder-custom">
                        <div class="row gutters">
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                <div class="form-group">
                                    <label>{{ t('LOTS.BLOCK') }}: </label>
                                    <label class="ml-1 value">{{ proposal?.properties?.block }}</label>
                                </div>
                            </div>
                            <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-9">
                                <div class="form-group">
                                    <label>{{ t('LOTS.LOT') }}: </label>
                                    <label class="ml-1 value">{{ proposal?.properties?.name }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="row gutters">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label>{{ t('RESERVE.REALTOR') }}: </label>
                                    <label class="ml-1 value">{{ proposal?.user_name }}</label>
                                </div>
                            </div>
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                <div class="form-group">
                                    <label for="reason_revocation" class="required">Motivo para rejeitar a proposta:</label>
                                    <textarea class="form-control" id="reason_revocation" cols="20" rows="10"
                                              v-model="proposal.motive_reject">
                                    </textarea>
                                    <p class="red" style="color: red">Mínimo 10 caracteres</p>
                                </div>
                            </div>
                        </div>
                        <div class="right">
                            <button data-cy="reserve_modal_close" type="button"
                                    class="btn btn-secondary mr-1 width-buttons" @click="closeModal">
                                {{ t('ACTIONS.CLOSE') }}
                            </button>
                            <button data-cy="reserve_modal_confirm" type="button"
                                    class="btn btn-primary mr-1 width-buttons" @click="rejectProposal()">
                                {{ t('ACTIONS.CONFIRM') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="closeModalRejectProposal"></div>
    </div>
</template>

<script>
import {useToast} from 'vue-toastification';
import {useI18n} from 'vue-i18n';
import Proposal from '../../services/Proposal';

export default {
    name: "modalRejectProposal",
    props: ['proposalIndex', 'modalInstance', 'position'],
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    data() {
        return {
            proposal:{motive_reject:null},
        }
    },
    mounted() {
        this.proposal = this.proposalIndex;
    },
    watch:{
        proposalIndex(){
            this.proposal = this.proposalIndex;
        }
    },
    methods:{
        closeModal(){
            this.modalInstance.hide();
            const backdrops = document.getElementsByClassName('modal-backdrop');
            if (backdrops[1] && this.position === 'two') {
                document.body.removeChild(backdrops[1]);
            }else if (backdrops[0] && this.position === 'one'){
                document.body.removeChild(backdrops[0]);
            }
        },
        rejectProposal() {
            if (!this.proposal?.motive_reject || this.proposal?.motive_reject?.length < 10){
                this.toast.error('Necessário informar um motivo para rejeição com no minimo 10 caracteres');
                return;
            }
            this.$store.commit('changeLoading', true);
            Proposal.rejectProposal(this.proposal).then(() => {
                this.closeModal();
                this.toast.success(this.t('PROPOSAL.REJECTE_SUCCESS'));
                this.$emit('proposalRejected', this.proposal);
            }).catch((err) => {
                this.$store.commit('changeLoading', false);
                this.errorMsg(err);
            });
        },
    }
}
</script>

<style scoped>

</style>