<script>
export default {
    name: "StatusProposal",
    methods:{
        translateColorStatus(status) {
            if (status === 'PENDENTE') return '#f38c7d';
            if (status === 'EM AVALIAÇÃO') return '#fb7f12';
            if (status === 'APROVADA') return '#3FBF3F';
            if (status === 'REJEITADA') return '#f34646';
            if (status === 'EXPIRADA') return '#cdca16';
            if (status === 'VENDA CANCELADA') return '#f34646';
            if (status === 'CANCELADA') return '#ff0000';
        },
        translateStatus(status) {
            if (status === 'EM AVALIAÇÃO') return 'Em Avaliação';
            if (status === 'APROVADA') return 'Aprovada';
            if (status === 'REJEITADA') return 'Rejeitada';
            if (status === 'EXPIRADA') return 'Expirada';
            if (status === 'PENDENTE') return 'Pendente';
            if (status === 'VENDA CANCELADA') return 'Venda Cancelada';
            if (status === 'CANCELADA') return 'Cancelada';
        },
        translateColorStatusAssign(status) {
            if (!status) {
                return ''
            }
            if (status === 6 || status === 2) {
                return '#3FBF3F';
            }
            if (status === 1) {
                return '#fb7f12';
            }
            if (status === 3) {
                return '#e0bb47';
            }
            if (status === 4 || status === 7) {
                return '#f34646';
            }
            if (status === 5) {
                return '#e90c66';
            }
        },
        getStatusClickSing(status) {
            if (!status) {
                return '-';
            }
            switch (status) {
                case 1:
                    return 'Aguardando';
                case 2:
                    return 'Assinado';
                case 3:
                    return 'Expirado';
                case 4:
                    return 'Cancelado';
                case 5:
                    return 'Cancelamento Solicitado'
                case 6:
                    return 'Ass. Manual Anexada';
                case 7:
                    return 'Ass. Manual Cancelada';
            }
        },
    }
}
</script>

<style scoped>

</style>