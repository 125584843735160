<template>
    <div>
        <div class="modal-row2 modal-height-max" id="myTabContent">
            <div class="tab-pane card" id="template" style="margin-bottom: 0rem">
                <div class="modal-body" style="height: 50vh; overflow-x: hidden">
                    <list-component :items="this.itens" @open="open($event)" :haveDetails="true" large-options>
                        <template v-slot:principal-info="{items}">
                            <div class="list-primary hide-text">{{ items.iten.name }}</div>
                            <div class="list-primary hide-text d-inline-flex">
                                <div :class="items.iten.status">{{ stats(items.iten.status) }}</div>
                                <div class="signature-quantity">{{ quantityOfSignatures(items.iten) }}</div>
                            </div>
                        </template>
                        <template v-slot:date="items">
                            <div style="text-align: center">
                                <label>{{ t('PROPOSAL.DEADLINE') }}:</label>
                                <div>{{ formatDate(items.iten.deadline_at) }}</div>
                            </div>
                        </template>
                        <template v-slot:dropdown-options="items">
                            <div class="dropdown-item pointer text-white w-auto"
                                 @click="viewDocument(items.iten.original_file_url)">
                                <i class="icon-eye1 mr-2 font-15"/>{{ t('PROPOSAL.VIEW_DOC_ORIGINAL') }}
                            </div>
                            <div v-if="items.iten.signed_file_url != null" class="dropdown-item pointer text-white"
                                 @click="viewDocument(items.iten.signed_file_url)">
                                <i class="icon-eye mr-2 font-15"/>{{ t('PROPOSAL.VIEW_DOC_ALREADY_SIGN') }}
                            </div>
                        </template>
                        <template v-slot:details-itens="items">
                            <div class="form-group mt-2" v-for="signer in items.iten.signers" v-bind:key="signer.id">
                                <ul>
                                    <li>
                                        <div v-if="signer.signed_at === null && items?.iten?.status === 'running'" class="whats pointer mt-3">
                                            <div v-if="signer.signed_at === null"
                                                 class="whats pointer mr-4 btn-primary">
                                                <a
                                                        :data-flow="'left'"
                                                        :data-tooltip="'Copiar Link de Solicitação de Assinatura'"
                                                        @click="copyToClipboard(signer.url_signature)">
                                                    <i class="icon-copy" style="color: #0a3a75"></i>
                                                </a>
                                            </div>
                                            <div style="inset-inline: auto"
                                                 class="ml-4">
                                                <a :data-flow="'left'"
                                                   :data-tooltip="'Solicitação de assinatura via whatsapp'"
                                                   @click="sendWhatsapp(signer)">
                                                    <img src="/img/whatsapp.png">
                                                </a>
                                            </div>
                                        </div>
                                        <div
                                                class="list-primary hide-text"
                                                style="display: inline-block">
                                            <div :data-tooltip=stats(signer.status_of_signature) data-flow="bottom">
                                                <div
                                                        id="statusSigner"
                                                        :class="selectClass(signer.status_of_signature)"></div>
                                            </div>
                                            <div
                                                    class="name-signer">{{ signer.name }}
                                            </div>
                                            <div
                                                    class="seller"
                                                    id="sing_as"> {{ stats(signer.sign_as) }}
                                            </div>
                                        </div>
                                        <div
                                                v-if="signer.signed_at !== null"
                                                class="date">
                                            <label>{{ t("DOCUMENTS.DATE_OF_ASSYGN") }}:</label>
                                            <div>{{ formatDate(signer.signed_at) }}</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </template>
                    </list-component>
                    <div class="card" v-if="noHave">
                        <div class="card-header"></div>
                        <div class="card-body pt-0">
                            <div class="text-center">
                                <h5>{{ t('PROPOSAL.NONE_DOCUMENT_FOUND') }}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {useToast} from 'vue-toastification';
import {useI18n} from 'vue-i18n';
import listComponent from './listComponent';
import moment from "moment";
import Proposal from '../../services/Proposal';
import Clipboard from 'clipboard';

export default {
    name: "ViewDocumentClickSignComponent",
    components: {
        listComponent,
    },
    props: {
        itensIndex: {
            type: Array,
            required: true,
        }
    },
    mounted() {
        this.itens = this.itensIndex;
    },
    watch: {
        itensIndex() {
            this.itens = this.itensIndex;
        }
    },
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    data() {
        return {
            itens: [],
        }
    },
    methods: {
        copyToClipboard(text) {
            const clipboard = new Clipboard('.btn-primary', {
                text: function () {
                    return text;
                }
            });
            clipboard.on('success', (e) => {
                this.toast.success('Link copiado com sucesso!');
                clipboard.destroy();
                e.clearSelection();
            });
            clipboard.onClick({currentTarget: document.querySelector('.btn-primary')});
        },
        formatDate(date) {
            if (date) {
                return moment(String(date)).format('DD/MM/YYYY H:mm')
            }
            return "";
        },
        open(client) {
            client.open = !client.open;
        },
        selectClass(status) {
            switch (status) {
                case null:
                    return 'signer-running'
                case 'signed':
                    return 'signer-signed'
                case 'canceled':
                    return 'signer-canceled'
            }
        },
        viewDocument(linkDocument) {
            window.open(linkDocument, '_blank');
        },
        sendWhatsapp(signer) {
            let companies = JSON.parse(localStorage.getItem('companies'));
            companies.forEach((i) => {
                if (i.id === signer.company_id) {
                    signer.company_name = i.text;
                }
            })
            this.$swal.fire({
                title: this.t('PROPOSAL.ARE_SURE_WANT_TO_SEND_WHATSAPP_MESSAGE'),
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: 'Enviar',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.commit('changeLoading', true);
                    Proposal.watsappClickSing(signer).then((resp) => {
                        this.$store.commit('changeLoading', false);
                        this.toast.success(resp.data.message);
                    }).catch((err) => {
                        this.$store.commit('changeLoading', false);
                        this.toast.error(err.response.data.message);
                    })
                }
            });
        },
        quantityOfSignatures(item) {
            let alreadySigned = [];

            item.signers.forEach((signed) => {
                if (signed.status_of_signature === 'signed') {
                    alreadySigned.push('assinou')
                }
            });

            return alreadySigned.length + "/" + item.signers.length
        },
        stats(word) {
            switch (word) {
                case null:
                    return 'Aguardando';
                case 'signed':
                    return 'Assinado';
                case 'running':
                    return 'Aguardando';
                case 'canceled':
                    return 'Cancelado';
                case 'closed':
                    return 'Finalizado';
                case 'sign':
                    return 'Assinar';
                case 'approve':
                    return 'Aprovador';
                case 'party':
                    return 'Participante';
                case 'witness':
                    return 'Testemunha';
                case 'intervening':
                    return 'Interveniente';
                case 'receipt':
                    return 'Recebimento';
                case 'endorser':
                    return 'Endossante';
                case 'endorsee':
                    return 'Endossatário';
                case 'administrator':
                    return 'Administrador';
                case 'guarantor':
                    return 'Avalista';
                case 'transferor':
                    return 'Cedente';
                case 'transferee':
                    return 'Cessionário';
                case 'contractee':
                    return 'Contratada';
                case 'contractor':
                    return 'Contratante';
                case 'joint_debtor':
                    return 'Devedor Solidário';
                case 'issuer':
                    return 'Emitente';
                case 'manager':
                    return 'Gestor';
                case 'buyer':
                    return 'Comprador';
                case 'seller':
                    return 'Vendedor';
                case 'attorney':
                    return 'Procurador';
                case 'legal_representative':
                    return 'Representante Legal';
                case 'co_responsible':
                    return 'Responsável Solidário';
                case 'validator':
                    return 'Validador';
                case 'ratify':
                    return 'Homologador';
                case 'lessor':
                    return 'Locador';
                case 'lessee':
                    return 'Locatário';
                case 'surety':
                    return 'Fiador';
                case 'broker':
                    return 'Corretor';
            }
        },
    }
}
</script>

<style scoped>

.teste {
    width: 6px;
    position: center;
    height: 4px;
    box-shadow: #3a424c;
    text-align: center;
    border-radius: 50%;
}

.date {
    display: inline-block;
    margin-left: 45%;
}

.whats {
    display: inline-block;
    margin-left: 45%;
    float: right;
    font-size: 15px;
}

.signature-quantity {
    font-size: 15px;
    margin-left: 5px;
    margin-top: 3px;
    color: #3a424c;
}

.seller {
    margin-left: 24px;
    font-size: 12px;
    color: #5f5f5f;
}

.name-signer {
    display: inline-block;
    width: 500px;
    margin-top: -3px;
    position: relative;
    margin-left: 23px;
}

.signer-canceled {
    display: inline-block;
    background-color: #ba0e0e;
    border-radius: 50%;
    width: 20px;
    height: 20px;
}

.signer-signed {
    display: inline-block;
    background-color: #3AB40D;
    border-radius: 50%;
    width: 20px;
    height: 20px;
}

.signer-running {
    display: inline-block;
    width: 20px;
    border-radius: 50%;
    height: 20px;
    background-color: #E75528;
}

.canceled {
    background-color: #ba0e0e;
    width: 80px;
    color: #EBF1EB;
    font-size: 11px;
    border-radius: 1em;
    text-align: center;
    padding: 5px;
    display: inline-block;
}

.running {
    background-color: #E75528;
    width: 80px;
    font-size: 11px;
    color: #EBF1EB;
    border-radius: 1em;
    text-align: center;
    padding: 5px;
    display: inline-block;
}

.closed {
    background-color: #0e5e4e;
    width: 80px;
    font-size: 11px;
    border-radius: 1em;
    color: #EBF1EB;
    text-align: center;
    padding: 5px;
    display: inline-block;
}

[data-tooltip] {
    position: absolute;
    cursor: pointer;
}

[data-tooltip]:before,
[data-tooltip]:after {
    line-height: 1;
    font-size: .9em;
    pointer-events: none;
    position: absolute;
    box-sizing: border-box;
    display: none;
    opacity: 0;
}

[data-tooltip]:before {
    content: "";
    border: 5px solid transparent;
    z-index: 100;
}

[data-tooltip]:after {
    content: attr(data-tooltip);
    text-align: center;
    min-width: 3em;
    max-width: 21em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 7px 8px;
    border-radius: 3px;
    background: #2B2525;
    color: #F5F5F5;
    z-index: 99;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
    display: block;
    opacity: 1;
}

[data-tooltip]:not([data-flow])::before,
[data-tooltip][data-flow="top"]::before {
    bottom: 100%;
    border-bottom-width: 0;
    border-top-color: #2B2525;
}

[data-tooltip]:not([data-flow])::after,
[data-tooltip][data-flow="top"]::after {
    bottom: calc(100% + 5px);
}

[data-tooltip]:not([data-flow])::before, [tooltip]:not([data-flow])::after,
[data-tooltip][data-flow="top"]::before,
[data-tooltip][data-flow="top"]::after {
    left: 50%;
    -webkit-transform: translate(-50%, -4px);
    transform: translate(-50%, -4px);
}

[data-tooltip][data-flow="bottom"]::before {
    top: 100%;
    border-top-width: 0;
    border-bottom-color: #0F0738;
}

[data-tooltip][data-flow="bottom"]::after {
    top: calc(100% + 5px);
}

[data-tooltip][data-flow="bottom"]::before, [data-tooltip][data-flow="bottom"]::after {
    left: 50%;
    -webkit-transform: translate(-50%, 8px);
    transform: translate(-50%, 8px);
}

[data-tooltip][data-flow="left"]::before {
    top: 50%;
    border-right-width: 0;
    border-left-color: #0F0738;
    left: calc(0em - 5px);
    -webkit-transform: translate(-8px, -50%);
    transform: translate(-8px, -50%);
}

[data-tooltip][data-flow="left"]::after {
    top: 50%;
    right: calc(100% + 5px);
    -webkit-transform: translate(-8px, -50%);
    transform: translate(-8px, -50%);
}

[data-tooltip][data-flow="right"]::before {
    top: 50%;
    border-left-width: 0;
    border-right-color: #0F0738;
    right: calc(0em - 5px);
    -webkit-transform: translate(8px, -50%);
    transform: translate(8px, -50%);
}

[data-tooltip][data-flow="right"]::after {
    top: 50%;
    left: calc(100% + 5px);
    -webkit-transform: translate(8px, -50%);
    transform: translate(8px, -50%);
}

[data-tooltip=""]::after, [data-tooltip=""]::before {
    display: none !important;
}
</style>