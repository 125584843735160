<template>
    <div>
        <div v-if="reserve?.business_proposal" id="business_proposal" class="modal-body">
            <div style="overflow-x: hidden; min-height: 60vh">
                <div class="card">
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-hover table-bordered"
                                   style="overflow: hidden; min-height: 130px">
                                <thead>
                                <tr>
                                    <th class="text-center">Selecione</th>
                                    <th class="text-center">N°</th>
                                    <th class="text-center">{{ this.t('PROPOSAL.INFO') }}</th>
                                    <th class="text-center">{{ this.t('PROPOSAL.CLIENT') }}</th>
                                    <th class="text-center">{{ this.t('PROPOSAL.STATUS') }}</th>
                                    <th class="text-center">{{ this.t('PROPOSAL.REALTOR') }}</th>
                                    <th class="text-center">{{ this.t('PROPOSAL.DATE_CREATE') }}</th>
                                    <th class="text-center">{{ this.t('PROPOSAL.END_DATE') }}</th>
                                    <th class="text-center">Assinatura</th>
                                </tr>
                                </thead>
                                <tr v-for="(iten, key) in reserve?.business_proposal" :key="key">
                                    <th class="text-center">
                                        <i class="icon-circle pointer"
                                           style="font-size: 18px" title="Selecionar"
                                           v-if="idSelected !== iten.id" @click="idSelected = iten.id">
                                        </i>
                                        <i class="icon-check-circle pointer" style="font-size: 18px; color: #0e5e4e"
                                           title="Selecionada"
                                           @click="idSelected = null"
                                           v-else>
                                        </i>
                                    </th>
                                    <td class="text-center">{{ getNumberProposal(iten) }}</td>
                                    <td class="text-center">
                                        <div class="d-flex w-100 justify-content-center align-items-center">
                                            <div class="dropdown position-absolute"
                                                 @mouseout="setModalHoover(`dropdownMenuModal${key}`)"
                                                 @mouseover="setModalHoover(`dropdownMenuModal${key}`)">
                                                <i class="icon-info-with-circle"
                                                   :id="`dropdownMenuModal${key}`"
                                                   data-toggle="dropdown"
                                                   aria-haspopup="true"
                                                   aria-expanded="false"></i>
                                                <div class="dropdown-menu p-2 w-auto"
                                                     :aria-labelledby="`dropdownMenuModal${key}`"
                                                     style="min-width: 30rem;">
                                                    <div class="row mb-2 p-2">
                                                        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                                                            <div class="font-weight-bolder mt-2 ml-4 text-left">
                                                                {{ this.t('PROPOSAL.PARCEL_CONFIG.TYPE') }}
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                            <div class="font-weight-bolder mt-2 ml-4 text-left">
                                                                {{ this.t('PROPOSAL.PARCEL_CONFIG.VALUE') }}
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                            <div class="font-weight-bolder mt-2 text-left">
                                                                {{
                                                                    this.t('PROPOSAL.PARCEL_CONFIG.NUMBER_PARCELS')
                                                                }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-for="(parcel, key) in iten?.parcel_configs" :key="key"
                                                         :class="key != 0 ? 'border-top2': ''">
                                                        <div class="row mw-100 space-between mt-2">
                                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                                <div class="list-primary">
                                                                    <div class="w-auto">
                                                                        {{ parcel.name }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                                <div class="list-primary">
                                                                    <div class="w-auto">
                                                                        {{ toCurrency(parcel.value) }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                                                                <div class="list-primary">
                                                                    <div class="w-auto">
                                                                        {{ parcel.number_parcels }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center">{{ getNameClient(reserve) }}</td>
                                    <td class="text-center">
                                        <div class="font-weight-bold align-self-center d-flex w-100 justify-content-center"
                                             style="margin-bottom: -2px; width: 110px; text-align: justify"
                                             id="status">
                                            <span class="rounded-pill pb-1 pt-1 text-center card pl-2 pr-2"
                                                  :style="{'background-color': translateColorStatus(iten.status) ,
                                                'color': 'white'}">
                                                {{ this.translateStatus(iten.status) }}
                                            </span>
                                        </div>
                                    </td>
                                    <td class="text-center"> {{
                                            reserve?.realtor_data?.name ? reserve.realtor_data.name : reserve?.realtor_name ? reserve?.realtor_name : 'Indefinido'
                                        }}
                                    </td>
                                    <td class="text-center"> {{ endDateFormat(iten.created_at, true) }}</td>
                                    <td class="text-center"> {{ endDateFormat(iten.end_date) }}</td>
                                    <td class="text-center">
                                        <div v-if="iten?.click_sign_documents?.status"
                                             :style="{'background-color': translateColorStatusAssign(iten?.click_sign_documents?.status), 'margin-bottom': '0'}"
                                             class=" card text-white font-weight-bold p-1"
                                             style="font-size: 12px">
                                            {{ getStatusClickSing(iten?.click_sign_documents?.status) }}
                                        </div>
                                        <div v-else style="font-size: 12px">
                                            {{ getStatusClickSing(iten?.click_sign_documents?.status) }}
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer">
            <button class="btn btn-primary" :disabled="idSelected === null" @click="aproveProposal">Aprovar Proposta</button>
        </div>
    </div>
</template>

<script>
import {useToast} from 'vue-toastification';
import {useI18n} from 'vue-i18n';
import Proposal from '../../services/Proposal';
import StatusProposal from '../../mixins/StatusProposal';

export default {
    name: "aproveProposalModal",
    props:['reserve', 'id'],
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    data(){
        return {
            idSelected: null,
        }
    },
    mixins:[StatusProposal],
    emits:['aproved'],
    mounted() {
        if (this.id){
            this.idSelected = this.id;
        }
    },
    watch:{
       id (){
           this.idSelected = this.id;
       }
    },
    methods:{
        aproveProposal(){
            if (!this.idSelected){
                this.toast.error('Necessario selecionar uma proposta');
                return;
            }
            let proposal = this.reserve.business_proposal.find(i => i.id === this.idSelected);
            this.$swal.fire({
                title: `Realmente deseja aprovar a proposta N°${this.getNumberProposal(proposal)} ?`,
                text: '',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: `Sim`,
                cancelButtonText: 'Não'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.commit('changeLoading', true);
                    Proposal.aproveProposal(proposal.id).then(() => {
                        this.$emit('aproved')
                        this.toast.success(this.t('PROPOSAL.APROVE_SUCCESS'));
                    }).catch(() => {
                        this.$store.commit('changeLoading', false);
                    })
                }
            });
        },
        endDateFormat(value, created = false) {
            if (value && !created) {
                let dateAndTime = value.split(' ');
                let date = dateAndTime[0].split('-');
                let time = dateAndTime[1].split(':');
                return `${date[2]}/${date[1]}/${date[0]} às ${time[0]}:${time[1]}`;
            } else if (value && created) {
                let dateAndTime = value.split('T');
                let date = dateAndTime[0].split('-');
                let time = dateAndTime[1].split(':');
                return `${date[2]}/${date[1]}/${date[0]} às ${time[0]}:${time[1]}`;
            }
            return 'Indefinido'
        },
        getNumberProposal(iten) {
            if (!iten?.properties?.id && this.reserve?.properties?.id) {
                iten.properties = this.reserve.properties;
            }
            let block = '';
            if (iten.properties?.block) {
                block = iten.properties.block.replace(/^0+/, '');
            }
            let lot = '';
            if (iten.properties?.name) {
                lot = iten.properties.name.replace(/^0+/, '');
            }
            if (iten.created_at) {
                let date = iten.created_at?.split('T');
                date = date[1]?.split(':');
                let sec = date[2]?.split('.')
                return `${block}${lot}${date[0]}${date[1]}${sec[0]}`;
            }
            return `${block}${lot}`;
        },
        getNameClient(iten) {
            let name = '';
            let clients = iten?.reserves_clients
            clients.forEach((item, key) => {
                if (clients[key + 1]) {
                    name += item.clients.name + ' ,';
                }
                name += item.clients.name;
            })
            return name;
        },
        setModalHoover(id) {
            document.getElementById(id).click();
        },
        toCurrency(value) {
            if (value === null) return 'Indisponível';
            return Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(value);
        },
    }
}
</script>

<style scoped>

</style>