<template>
    <div class="dropdown-menu dropdown-menu-right dropdown-black" style="min-width: max-content">
        <div class="dropdown-item pointer text-white"
             v-if="iten.status === 'EM AVALIAÇÃO' && !index.isRealtor()"
             data-target="#customModalTwo"
             data-toggle="modal"
             @click="index.setModalAproveProposal(iten)">
            <i class="icon-thumbs-up mr-2 font-15"/>{{t('PROPOSAL.APROVE')}}
        </div>
        <div class="dropdown-item pointer text-white"
             data-toggle="modal" data-target="#customModalTwo"
             v-if="(iten.click_sign_documents?.clicksign_id || index.isAssingManual(iten.click_sign_documents)) && (index.hasConfig || index.isAssingManual(iten.click_sign_documents))"
             @click="index.viewDocumentClickSing(iten?.click_sign_documents?.clicksign_id, iten)">
            <i class="icon-eye mr-2 font-15"/>{{
                index.isAssingManual(iten.click_sign_documents) ? t('PROPOSAL.VIEW_CLICKSIGN') : 'Assinaturas'
            }}
        </div>
        <div class="dropdown-item pointer text-white"
             v-if="(iten.status === 'EM AVALIAÇÃO' && index.canSocityClickSign(iten?.click_sign_documents?.status))"
             @click="index.alterPending(iten)">
            <i class="icon-edit mr-2 font-15"/>{{
                t('PROPOSAL.SET_PENDING')
            }}
        </div>
        <div class="dropdown-item pointer text-white"
             v-if="(iten.status === 'PENDENTE')"
             @click="index.alterAvaliation(iten.id)">
            <i class="icon-edit mr-2 font-15"/>{{
                t('PROPOSAL.SET_AVALIATION')
            }}
        </div>
        <div class="dropdown-item pointer text-white"
             v-if="(iten.status === 'EM AVALIAÇÃO' && !iten.hasSignature ) ||
                                            (iten.status === 'APROVADA' && !iten.hasSignature)"
             @click="index.setFile(iten.id)">
            <i class="icon-attach_file mr-2 font-15"/>{{
                t('PROPOSAL.SOLICIT_ASSIGN_MANUAL')
            }}
        </div>
        <div class="dropdown-item pointer text-white"
             v-if="(iten.status === 'EM AVALIAÇÃO' && !index.isRealtor()  && index.hasConfig && iten.click_sign_documents?.status === 1 && !index.isAssingManual(iten.click_sign_documents)) ||
                                            (iten.status === 'APROVADA' && !index.isRealtor()  && index.hasConfig && iten.click_sign_documents?.status === 1 && !index.isAssingManual(iten.click_sign_documents)) "
             @click="index.cancelClickSing(iten.id)">
            <i class="icon-x-circle mr-2 font-15"/>{{ t('PROPOSAL.CANCEL_CLICKSIGN') }}
        </div>
        <div class="dropdown-item pointer text-white"
             v-if="(iten.status !== 'APROVADA' &&
                                             iten.hasSignature &&
                                             index.isAssingManual(iten.click_sign_documents) &&
                                             !index.isRealtor())"
             @click="index.deleteAssignManual(iten.id)">
            <i class="icon-trash mr-2 font-15"/>{{
                t('PROPOSAL.DELETE_ASSIGN_MANUAL')
            }}
        </div>
        <div class="dropdown-item pointer text-white"
             data-toggle="modal" data-target="#customModalTwo"
             v-if="iten.status === 'EM AVALIAÇÃO' && !index.isRealtor()"
             @click="index.setModalEdit(iten.id)">
            <i class="icon-pencil mr-2 font-15"/>{{ t('PROPOSAL.EDIT') }}
        </div>
        <div class="dropdown-item pointer text-white"
             data-toggle="modal" data-target="#customModalTwo"
             @click="index.setModalClientAnexView(iten)">
            <i class="icon-people mr-2 font-15"/>{{ t('PROPOSAL.CLIENT_FICH') }}
        </div>
        <div class="dropdown-item pointer text-white"
             v-if="(iten.status === 'EM AVALIAÇÃO' && index.canSocityClickSign(iten?.click_sign_documents?.status))"
             @click="index.regenerateProposal(iten.id)">
            <i class="icon-autorenew mr-2 font-15"/>{{
                t('PROPOSAL.REGENERATE')
            }}
        </div>
        <div class="dropdown-item pointer text-white"
             v-if="!index.isRealtor() && !iten.has_contract && iten.status === 'APROVADA'"
             @click="index.generateContract(iten)">
            <i class="icon-documents mr-2 font-15"/>{{
                t('PROPOSAL.GENERATE_CONTRACT')
            }}
        </div>
        <div class="dropdown-item pointer text-white"
             v-if="(iten.status === 'EM AVALIAÇÃO' || iten.status === 'APROVADA') && !index.isRealtor() && (!iten.click_sign_documents?.clicksign_id || (iten.click_sign_documents?.status !== 2 && iten.click_sign_documents?.status !== 6))"
             @click="index.setModalRejectProposal(iten)">
            <i class="icon-thumbs-down mr-2 font-15"/>{{ t('PROPOSAL.REJECT') }}
        </div>
        <div class="dropdown-item pointer text-white"
             v-if="(iten.status === 'EM AVALIAÇÃO'  && index.hasConfig && index.canSocityClickSign(iten?.click_sign_documents?.status)) ||
                                            (iten.status === 'APROVADA' && index.hasConfig && index.canSocityClickSign(iten?.click_sign_documents?.status))"
             @click="index.solicitClickSing(iten.id)">
            <i class="icon-border_color mr-2 font-15"/>{{
                t('PROPOSAL.SOLICIT_CLICKSIGN')
            }}
        </div>
        <div class="dropdown-item pointer text-white" v-if="iten.has_contract"
             @click="index.reprintContract(iten)">
            <i class="icon-eye1 mr-2 font-15"/>{{ t('CONTRACT.VIEW') }}
        </div>
        <div class="dropdown-item pointer text-white"
             @click="index.reprintProposal(iten)">
            <i class="icon-eye1 mr-2 font-15"/>{{ t('PROPOSAL.VIEW') }}
        </div>

    </div>
</template>

<script>
import {useToast} from 'vue-toastification';
import {useI18n} from 'vue-i18n';

export default {
    name: "optionsDropdowIndexComponent",
    props: ['index', 'iten'],
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
}
</script>

<style scoped>
.task-list .task-block .dropdown-menu:before {
    border-bottom: 0px solid #262b31 !important;
}
</style>