<script>
export default {
name: "updateCountdownProposal",
    methods:{
        updateCountdown(item) {
            if (!item.end_date) {
                clearInterval(this.intervalExpiration[item.id]);
                delete this.intervalExpiration[item.id];
                return;
            }
            if (['REJEITADA', 'CANCELADA', 'EXPIRADA', 'VENDA CANCELADA'].includes(item.status)){
                clearInterval(this.intervalExpiration[item.id]);
                if (!this.timeExpirationNow[item.id]) this.timeExpirationNow[item.id] = {};
                this.timeExpirationNow[item.id].countdown = null;
                return;
            }
            const now = new Date();
            const futureDate = new Date(item.end_date);
            let diff = futureDate - now;
            if (diff <= 0) {
                clearInterval(this.intervalExpiration[item.id]);
                if (!this.timeExpirationNow[item.id]) this.timeExpirationNow[item.id] = {};
                this.timeExpirationNow[item.id].countdown = '00:00'
                return;
            }
            const hours = Math.floor(diff / (1000 * 60 * 60));
            diff -= hours * (1000 * 60 * 60);
            const minutes = Math.floor(diff / (1000 * 60));
            diff -= minutes * (1000 * 60);
            const seconds = Math.floor(diff / 1000);
            if (!this.timeExpirationNow[item.id]) this.timeExpirationNow[item.id] = {};
            this.timeExpirationNow[item.id].countdown = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        },
    }
}
</script>

<style scoped>

</style>