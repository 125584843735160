<template>
    <div>
        <div class="modal-body">
            <div class="row gutters  p-2 ">
                <div class="col-12 row mb-2">
                    <div class="list-primary hide-text col-7 font-weight-bold"> &nbsp;Cliente</div>
                    <div class="list-primary hide-text col-4 font-weight-bold">CPF</div>
                </div>
                <div class="col-12 row" v-for="(client, key) in data" :key="key" :class="key !=0 ? 'border-top2': ''">
                    <div class="list-primary hide-text col-7"> &nbsp;{{ client.clients.name }}</div>
                    <div class="list-primary hide-text col-4">{{ client.clients.taxpayer }}</div>
                    <div class="list-primary hide-text col-1"><i class="icon-download pointer" title="baixar ficha do cliente"
                                                                 @click="printClientPdf(client.clients)"></i></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {useToast} from 'vue-toastification';
import {useI18n} from 'vue-i18n';
import {PDFDocument} from 'pdf-lib';
import Clients from '@/services/Clients';

export default {
    name: "listPersonDownloadFich",
    props: ['clients'],
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    data(){
        return{
            data:[],
        }
    },
    mounted() {
        this.data = this.clients
    },
    methods:{
        async printClientPdf(iten) {
            this.$store.commit('changeLoading', true);
            await Clients.exportPdfClient(iten.id).then(async (response) => {
                if (response.data?.length) {
                    const mergedPdf = await PDFDocument.create();
                    for (const pdfObj of response.data) {
                        if (pdfObj?.basefile) {
                            const pdfBase64 = pdfObj.basefile;
                            const pdfBytes = this.base64ToUint8Array(pdfBase64);
                            try {
                                const pdfDoc = await PDFDocument.load(pdfBytes);
                                const copiedPages = await mergedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
                                copiedPages.forEach((page) => mergedPdf.addPage(page));
                            } catch (err) {
                                const blob = new Blob([pdfBytes], {type: 'application/pdf'});
                                const url = URL.createObjectURL(blob);
                                const a = document.createElement('a');
                                a.href = url;
                                let fileName = response.data.find(i => i)?.name ? response.data.find(i => i)?.name : 'arquivoPessoas.pdf';
                                fileName = fileName?.replaceAll('.pdf', '');
                                fileName = fileName + (pdfObj?.name ? pdfObj?.name : '.pdf');
                                a.download = fileName;
                                document.body.appendChild(a);
                                a.click();
                                document.body.removeChild(a);
                                URL.revokeObjectURL(url);
                            }
                        }
                    }

                    const mergedPdfFile = await mergedPdf.save();
                    const blob = new Blob([mergedPdfFile], {type: 'application/pdf'});
                    const url = URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    let fileName = response.data.find(i => i)?.name ? response.data.find(i => i)?.name : 'arquivoPessoas.pdf'
                    a.href = url;
                    a.download = fileName;
                    a.style.display = 'none';
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                    URL.revokeObjectURL(url);
                }
                this.$store.commit('changeLoading', false);
            }).catch((err) => {
                this.$store.commit('changeLoading', false);
                this.errorMsg(err);
            })
        },
        base64ToUint8Array(base64) {
            const base64Data = base64.replace(/^data:application\/pdf;base64,/, '');
            const binaryString = window.atob(base64Data);
            const len = binaryString.length;
            const bytes = new Uint8Array(len);
            for (let i = 0; i < len; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            return bytes;
        },
    }
}
</script>

<style scoped>

</style>