<template>
    <div>
        <div class="modal-body">
            <div style="overflow-x: hidden; min-height: 40vh">
                <div class="d-flex justify-content-end">
                    <button class="btn btn-primary mb-2 mt-1" @click="setModalFilter">Filtrar</button>
                </div>
                <div class="card mt-1" v-if="reserves?.data?.length">
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table table-hover table-bordered" style="overflow: hidden">
                                <thead>
                                <tr>
                                    <th class="text-center">Corretor</th>
                                    <th class="text-center">Cliente</th>
                                    <th class="text-center">Loteamento</th>
                                    <th class="text-center">Q.</th>
                                    <th class="text-center">L.</th>
                                    <th class="text-center">Duração</th>
                                    <th class="text-center">Gerar</th>
                                </tr>
                                </thead>
                                <tr v-for="(iten, key) in reserves?.data" :key="key">
                                    <td class="text-center pt-0 pb-0">{{ iten.realtorName }}</td>
                                    <td class="text-center pt-0 pb-0">{{ setClient(iten.reserves_clients) }}</td>
                                    <td class="text-center pt-0 pb-0">
                                        {{
                                            iten?.properties?.allotments?.name ? iten?.properties?.allotments?.name : 'Indefinido'
                                        }}
                                    </td>
                                    <td class="text-center pt-0 pb-0">{{ iten.properties?.block }}</td>
                                    <td class="text-center pt-0 pb-0">{{ iten.properties?.name }}</td>
                                    <td class="text-center pt-0 pb-0">
                                        {{
                                            timeExpirationNow[iten.id]?.countdown ? timeExpirationNow[iten.id]?.countdown : '?'
                                        }}
                                    </td>
                                    <td class="pl-0 pr-0">
                                        <div class="d-flex justify-content-center">
                                            <button class="btn btn-primary d-flex flex-row"
                                                    @click="generateProposal(iten)"><i
                                                    class="icon-download mr-1 align-self-center"></i>Gerar
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="card" v-if="!reserves?.data?.length">
                    <div class="card-header"></div>
                    <div class="card-body pt-0">
                        <div class="text-center">
                            <h5>Nenhuma reserva disponível neste loteamento</h5>
                        </div>
                    </div>
                </div>
                <pagination-component
                        v-if="reserves && reserves.data && reserves.data[0]"
                        :items="reserves"
                        :to="'/proposatas'"
                        @changePage="indexReserve($event)"></pagination-component>
            </div>
        </div>
        <transition name="fade">
            <div class="modal-mask" v-if="modalProposal">
                <div class="modal-wrapper" data-backdrop="static" data-keyboard="false">
                    <div class="modal-dialog" role="document" style="margin-top: 0rem; min-width: 90vw">
                        <div class="modal-content">
                            <div class="modal-header modal-header-color">
                                <h4 class="modal-title">{{ t('LOTS.CREATE_PROPOSE') }}</h4>
                                <button type="button" class="closeX" @click="cancel()">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <iframe style="height: 80vh!important;"
                                    :allow="`clipboard-read ${hostSimulator}; clipboard-write ${hostSimulator}`"
                                    :src="srcIframe"
                                    sandbox="allow-downloads allow-same-origin allow-scripts allow-clipboard-read allow-clipboard-write"
                                    v-if="modalProposal && srcIframe"/>
                            <div class="modal-footer justify-content-end">
                                <button class="btn btn-secondary mr-4" type="button" @click="cancel()">
                                    {{ t("ACTIONS.CLOSE") }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
        <transition name="fade">
            <div class="modal-mask" v-if="modalFilter">
                <div class="modal-wrapper" data-backdrop="static" data-keyboard="false">
                    <div class="modal-dialog modal-xl" role="document" style="margin-top: 0rem;">
                        <div class="modal-content">
                            <div class="modal-header modal-header-color">
                                <h4 class="modal-title">Filtrar</h4>
                                <button type="button" class="closeX" @click="modalFilter = false">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="row gutters">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label> Loteamento</label>
                                            <Select2 :settings="{width: '100%', placeholder: 'Selecione um Loteamento'}"
                                                     :options="allotments"
                                                     disabled
                                                     v-model="allotmentId"/>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div class="form-group">
                                            <label>{{ t('RESERVE.REALTOR') }}: </label>
                                            <Select2 :settings="{ width: '100%', placeholder: 'Selecione um Corretor'}"
                                                     :options="realtors" @select="selectRealtor"
                                                     v-model="filter.realtorId" :disabled="!realtors?.length"/>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <div class="form-group">
                                            <label>{{ t('RESERVE.TYPE') }}: </label>
                                            <Select2 :settings="{ width: '100%', placeholder: 'Selecione um Tipo'}"
                                                     :options="[{id:'Nenhum', text: 'Nenhum'},...reserveTypes]"
                                                     v-model="filter.reserveType"/>
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div class="form-group">
                                            <label>{{ t('RESERVE.CLIENT') }}: </label>
                                            <Select2 :settings="ajaxPerson"
                                                     :placeholder="filter?.clientName ? filter.clientName : 'Selecione'"
                                                     @select="selectClient"
                                                     v-model="filter.clientId"/>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                                        <div class="form-group">
                                            <label>{{ t('PROPERTIES.BLOCK') }}: </label>
                                            <Select2 :settings="{ width: '100%', placeholder: 'Selecione uma Quadra'}"
                                                     :options="blocks"
                                                     v-model="filter.block" @select="searchBlock($event)"/>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
                                        <div class="form-group">
                                            <label>{{ t('PROPERTIES.NAME') }}: </label>
                                            <Select2 id="lots"
                                                     :settings="{ width: '100%', placeholder: 'Selecione um lote'}"
                                                     :options="lots"
                                                     v-model="filter.lots" @select="searchLots($event)"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer justify-content-end">
                                <button class="btn btn-secondary mr-2" type="button" @click="modalFilter = false">
                                    {{ t("ACTIONS.CLOSE") }}
                                </button>
                                <button class="btn btn-secondary mr-2" type="button" @click="clearFilter">
                                    Limpar
                                </button>
                                <button class="btn btn-primary " type="button"
                                        @click="modalFilter = false; indexReserve()">
                                    Filtrar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import {useToast} from 'vue-toastification';
import {useI18n} from 'vue-i18n';
import PaginationComponent from '../../components/layouts/PaginationComponent';
import Reserves from '../../services/Reserves';
import BuildSimulatorUrl from '@/Helpers/BuildSimulatorUrl';
import {mapState} from 'vuex';
import Select2 from 'vue3-select2-component';
import Lots from '../../services/Lots';
import User from '@/services/user';
import ReserveTypes from '../../services/ReserveTypes';

export default {
    name: "createProposalComponent",
    setup() {
        const toast = useToast();
        const {t} = useI18n();
        return {t, toast}
    },
    components: {
        PaginationComponent,
        Select2,
    },
    computed: {
        ...mapState({
            allotments: 'allotments',
        }),
        hostSimulator() {
            return process.env.VUE_APP_SIMULATOR_HOST;
        },
    },
    mixins: [BuildSimulatorUrl],
    data() {
        return {
            modalFilter: false,
            realtors: [],
            status: [],
            reserveTypes: [],
            blocks: [],
            lots: [],
            intervalExpiration: {},
            reserves: {},
            allotmentId: null,
            srcIframe: null,
            modalProposal: false,
            timeExpirationNow: {},
            filter: {
                reserveStatusType: 0,
                OnlyDisponibleProposal: true
            },
            ajaxPerson: {
                width: '100%',
                language: {
                    inputTooShort: function () {
                        return "Por favor, insira ao menos 1 caracteres para pesquisar.";
                    },
                    noResults: function () {
                        return "Nenhum resultado encontrado!";
                    }
                },
                minimumInputLength: 1,
                ajax: {
                    url: `${process.env.VUE_APP_BACK_END}/api/v1/clientes/list`,
                    dataType: "json",
                    headers: {
                        'authorization': `Bearer ${localStorage.getItem('access_token')}`,
                        'company-id': localStorage.getItem('companyId'),
                    },
                    data: params => {
                        return {
                            term: params.term,
                        };
                    },
                    processResults: function (data) {
                        if (!(data.validations)) {
                            data = data.map((i) => ({id: i.id, text: i.name}));
                            data.unshift({id: 0, text: "Nenhum"});
                            return {
                                results: data
                            };
                        } else {
                            return {results: {id: 0, text: "Nenhum"}};
                        }
                    },
                    cache: true
                },
                initSelection: function (element, callback) {
                    callback({id: 1, text: 'initSelection test'});
                },
            },
        }
    },
    beforeUnmount() {
        Object.values(this.intervalExpiration)?.forEach((interval) => {
            clearInterval(interval);
        });
    },
    mounted() {
        this.allotmentId = localStorage.getItem('proposalAllotmentId');
        this.$store.commit('changeLoading', false)
        this.indexReserve();
        this.indexRealtor();
    },
    methods: {
        selectRealtor({text}){
            if (text === 'Nenhum'){
                delete this.filter.realtorId;
            }
        },
        searchLots({text}) {
            if (text == 'Nenhum'){
                delete this.filter.lots;
            }
        },
        searchBlock({text}) {
            if (text == 'Nenhum'){
                delete this.filter.block;
            }
        },
        clearFilter(){
            this.$store.commit('changeLoading', true)
            this.modalFilter = false;
            this.filter = {reserveStatusType: 0, OnlyDisponibleProposal: true};
            this.indexReserve();
        },
        setModalFilter() {
            this.$store.commit('changeLoading', true);
            this.modalFilter = true;
            Promise.all([
                this.getLots(),
                this.indexBlocks(),
                this.getRerservesType(),
            ]).then(() => this.$store.commit('changeLoading', false)).catch(() => this.$store.commit('changeLoading', false))
        },
        async getRerservesType() {
            await ReserveTypes.list(this.allotmentId).then(resp => {
                this.reserveTypes = resp.data;
            })
        },
        async indexRealtor() {
            await User.listRealtor(this.allotmentId).then(resp => {
                if (!resp.data.validations) {
                    this.realtors = [];
                    this.realtors = resp.data.filter(item => {
                        if (typeof item === 'object' && item !== null) {
                            return {
                                id: item.id,
                                text: item.text,
                            }
                        }
                    });
                    this.realtors.unshift({id: 0, text: this.t('GENERAL.NONE')});
                } else this.realtors = [{id: 0, text: 'Nenhum'}];
            }).catch(error => {
                this.errorMsg(error);
            });
        },
        async indexBlocks() {
            await Lots.blocksList(this.allotmentId).then(async resp => {
                if (!resp.data?.validations) {
                    resp.data = resp.data.map(i => {
                        return {
                            id: i.text,
                            text: i.text,
                        }
                    })
                    this.blocks = [{id: 0, text: 'Nenhum'}, ...resp.data];
                }
            }).catch(error => {
                this.errorMsg(error);
            });
        },
        async getLots() {
            await Lots.getLotsByAllotment(this.allotmentId).then(resp => {
                this.lots = [{id: 0, text: 'Nenhum'}, ...resp.data];
            })
        },
        selectClient({id, text}) {
            if (text === 'Nenhum') {
                this.filter.clientId = null;
                this.filter.clientName = null;
            } else {
                this.filter.clientId = id;
                this.filter.clientName = text;
            }
        },
        cancel() {
            this.indexReserve();
            this.modalProposal = false;
        },
        async generateProposal(reserve) {
            this.modalProposal = true;
            if (!reserve?.reserves_clients?.length) {
                this.modalProposal = false;
                setTimeout(() => document.getElementById('closeX')?.click(), 500);
                this.$store.commit('changeLoading', false);
                this.toast.error('Não é possivel gerar proposta para reserva que não possui clientes vinculados');
                return;
            }
            const {properties} = reserve;
            await this.buildSimulatorUrl(properties.id).then(url => this.srcIframe = url).catch(err => this.errorMsg(err));
        },
        updateCountdown(item) {
            if (!item?.permanences_active[0]?.finish) {
                clearInterval(this.intervalExpiration[item.id]);
                delete this.intervalExpiration[item.id];
                return;
            }
            const now = new Date();
            const futureDate = new Date(item.permanences_active[0].finish);
            let diff = futureDate - now;
            if (diff <= 0) {
                clearInterval(this.intervalExpiration[item.id]);
                if (!this.timeExpirationNow[item.id]) this.timeExpirationNow[item.id] = {};
                this.timeExpirationNow[item.id].countdown = '00:00';
                return;
            }
            const hours = Math.floor(diff / (1000 * 60 * 60));
            diff -= hours * (1000 * 60 * 60);
            const minutes = Math.floor(diff / (1000 * 60));
            diff -= minutes * (1000 * 60);
            const seconds = Math.floor(diff / 1000);
            if (!this.timeExpirationNow[item.id]) this.timeExpirationNow[item.id] = {};
            this.timeExpirationNow[item.id].countdown = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
        },
        async indexReserve(page = 1) {
            this.$store.commit('changeLoading', true);
            Object.values(this.intervalExpiration)?.forEach((interval) => {
                clearInterval(interval);
            });
            await Reserves.index(this.filter, this.allotmentId, page, this.perPage).then(resp => {
                resp?.data?.data?.forEach(i => {
                    this.intervalExpiration[i.id] = setInterval(() => this.updateCountdown(i), 100);
                });
                this.reserves = resp.data;
                this.$store.commit('changeLoading', false)
            }).catch(error => {
                this.$store.commit('changeLoading', false);
                this.errorMsg(error);
            });
        },
        setClient(clients) {
            let client;
            clients.forEach((element, key) => {
                if (element.clients) {
                    client = key === 0 ? element.clients?.name : client + ', ' + element.clients?.name;
                }
            });
            if (typeof client == 'undefined') {
                client = this.t('GENERAL.MSG.NONE_COSTUMER_LIKED');
            }
            return client;
        },
    }
}
</script>

<style scoped>

</style>